$gold: rgb(255, 193, 42);
$gold-dark: rgb(255, 159, 24);
$gold-dark-half: hsla(40, 96%, 53%, 0.5);

.Signin {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: url("../../images/signin.png") 0 / cover fixed;
    font-family: "Roboto" !important;
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3em;
    background: #ffffffcc;
    color: white;
    border-radius: 10px;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.5);
    width: 400px;
    animation-name: fade-in;
    animation-fill-mode: both;
    animation-duration: 1s;
  
    h1 {
      font-size: 2.5em;
      margin-bottom: 0.3em;
      padding: 12px 0;
    }
  
    .form-field {
      position: relative;
      font-size: 20px;
      border-bottom: 1px solid #363636;
      margin: 20px 0 8px 0;
      transition: 0.4s;
      width: 100%;
  
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        bottom: -1px;
        left: 0;
        background: $gold;
        transform-origin: left;
        transform: scaleX(0);
        transition: 0.4s ease;
      }
  
      &:focus-within::after {
        transform: scaleX(1);
      }
  
      input {
        border: none;
        outline: none;
        background: transparent;
        color: #363636;
        padding-left: 10px;

        &:focus ~ label,
        &:not(:placeholder-shown) ~ label {
          top: 0;
          left: 0;
          font-size: 15px;
        }
  
        &:valid ~ label {
          color: $gold;
        }
  
        &:invalid ~ label {
          color: #9b9b9b;
        }
      }
  
      label {
        position: absolute;
        left: 32px;
        top: 24px;
        transition: 0.4s;
      }
    }
    .primary {
      background: $gold;
      color: #363636;
      font-size: 1.2em;
      border: 1px solid $gold-dark;
      margin: 36px 0 18px 0;
      padding: 6px 0;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        background: $gold-dark;
      } 
    }
    .login-form-forgot {
      color: $gold-dark;
      &:hover {
        color: $gold-dark-half;
        text-decoration: underline;
      }
    }
  }

}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}